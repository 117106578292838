import { DocumentSigningStatus } from "@/constants/SignatureConstants";

export default class SignableDocumentsFilter {

    signerId: number | null = null;

    signingStatus: DocumentSigningStatus | null = null;

    pageNumber = 1;

    pageSize = 20;

}
